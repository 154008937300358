
import { defineComponent, onMounted, ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import ApiService from '@/core/services/ApiService';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';

import PricePlanModal from '@/components/modals/forms/PricePlanModel.vue';

import VideoContentModal from '@/components/modals/forms/VideoContentModal.vue';

import AddVideoAsset from '@/components/modals/forms/AddVideoAsset.vue';

export default defineComponent({
    name: 'user-details',
    components: {
        PricePlanModal,
        VideoContentModal,
        AddVideoAsset
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const formRef = ref<null | HTMLFormElement>(null);
        const loading = ref<boolean>(false);

        const isEdit = route.params.id;
        const userId = ref(route.params.id);

        const subscriptionObj = ref<any>(undefined);
        let videoAssetObj = ref<any>(undefined);
        const defaultFilterType = ref('');

        const videoKey = ref('mediaId');

        const formData = ref({
            customerId: 0,
            id: 0,
            voucherName: '',
            promocode: '',
            emailSendFor: '',
            activationsLimit: 1,
            validFrom: '',
            validTo: '',
            itemType: '',
            itemId: '',
            pricePlanId: '',
            voucherstatus: '',
            voucherRange: 1,
            free: true,
            isPublic: true,
            status: '',
            durationValue: 1,
            durationUnit: '',
            payUntill: '',
            isDeleted: false,
        });

        const { role } = store.getters.currentUser;

        const setSelectedPricePlan = item => {
            console.log('item:', item);

            subscriptionObj.value={
                orderId: null,
                pricePlanId: item.pricePlanId,
                status: "succeeded",
                adyenSubscription: false,
                validTill: "2025-09-30T00:00:00",
                colorValidTill: true,
                pricePlanName: item.title,
                pricePlanType: item.planType,
                price: item.price
            };

            formData.value.pricePlanId = item.pricePlanId;
        };

        onMounted(() => {
            setCurrentPageBreadcrumbs(`${isEdit ? 'Edit' : 'Add'} Voucher`, ['Vouchers']);
            if (isEdit) {
                getVoucherDetails();
            }
            else
            {
                formData.value.status = "Initialized"
            }
        });

        const rules = ref({
            voucherName: [
                {
                    required: true,
                    message: 'Voucher name is required',
                    trigger: 'change'
                }
            ],
            promocode: [
                {
                    required: true,
                    message: 'promo code is required',
                    trigger: 'change'
                }
            ],
            emailSendFor: [
                {
                    required: true,
                    message: 'E-mail is required',
                    trigger: 'change'
                }
            ],
            activationsLimit: [
                {
                    required: true,
                    message: 'Activation limit is required',
                    trigger: 'change'
                }
            ],
            durationValue: [
                {
                    required: true,
                    message: 'Duration value is required',
                    trigger: 'change'
                }
            ],
            pricePlanId: [
                {
                    required: true,
                    message: 'Priceplan is required',
                    trigger: 'change'
                }
            ],
            durationUnit: [
                {
                    required: true,
                    message: 'Duration unit is required',
                    trigger: 'change'
                }
            ],
            validFrom: [
                {
                    required: true,
                    message: 'Valid from unit is required',
                    trigger: 'change'
                }
            ],
            validTo: [
                {
                    required: true,
                    message: 'Valid until must be grater than current date',
                    trigger: 'change',
                    asyncValidator: (rule, value) => {
                        return new Promise((resolve, reject) => {
                            if (value < Date.now()) {
                            reject('d');  // reject with error message
                            } else {
                            resolve(value);
                            }
                        });
                    },
                }
            ],
            itemType: [
                {
                    required: true,
                    message: 'Item type unit is required',
                    trigger: 'change'
                }
            ],
            status:[
                {
                    message: 'Vocher already activated and can\'t be edited',
                    trigger: 'change',
                    asyncValidator: (rule, value) => {
                        return new Promise((resolve, reject) => {
                            if (value === "Activated") {
                            reject('d');  // reject with error message
                            } else {
                            resolve(value);
                            }
                        });
                    },
                }
            ]
        });

        const submit = () => {
            if (!formRef.value) {
                return;
            }

            formRef.value.validate(valid => {
                if (valid && formData.value.pricePlanId != '') {
                    loading.value = true;

                    const data: any = formData.value;

                    if(data.itemType === 'Subscription')
                    {
                        data.itemId = data.pricePlanId;
                    }
                    else
                    {
                        if(data.itemId === '')
                        {
                            Swal.fire({
                                text: 'Sorry, you need to specify Item, please try again.',
                                icon: 'warn',
                                buttonsStyling: false,
                                confirmButtonText: 'Ok, got it!',
                                customClass: {
                                    confirmButton: 'btn btn-primary'
                                }
                    });
                    return false;
                        }
                    }
                    Object.keys(data).forEach(key => {
                        if (data[key] === null || data[key] === '') {
                            delete data[key];
                        }
                    });

                    let serviceType;

                    if (isEdit) {
                        serviceType = ApiService.put(
                            `Voucher/${route.params?.id}`,
                            data
                        );
					} 
                    else {
						delete data['Id'];
						serviceType = ApiService.post('Voucher', data);
					}

                    serviceType
                        .then(({ data }) => {
                            loading.value = false;

                            Swal.fire({
                                timer: 1500,
                                text: 'Form has been successfully submitted!',
                                icon: 'success',
                                buttonsStyling: false,
                                confirmButtonText: 'Ok, got it!',
                                customClass: {
                                    confirmButton: 'btn btn-primary'
                                }

                            }).then(() => {
                                if (isEdit) {
                                    formData.value = { ...formData.value, ...data };
                                } else {
                                    (
                                        document.getElementById('formRef') as HTMLFormElement
                                    ).reset();
                                }
                            });
                        })
                        .catch(({ response }) => {
                            console.log('Create/update voucher error', response);
                            Swal.fire({
                                text: 'Sorry, looks like there are some errors detected, please try again.',
                                icon: 'error',
                                buttonsStyling: false,
                                confirmButtonText: 'Ok, got it!',
                                customClass: {
                                    confirmButton: 'btn btn-primary'
                                }
                            });
                        });
                } else {
                    Swal.fire({
                        text: 'Sorry, looks like there are some validation errors detected, please try again.',
                        icon: 'warning',
                        buttonsStyling: false,
                        confirmButtonText: 'Ok, got it!',
                        customClass: {
                            confirmButton: 'btn btn-primary'
                        }
                    });
                    return false;
                }
            });
        };

        const getVoucherDetails = () => {
            loading.value = true;
            ApiService.get(`Voucher/${route.params.id}`)
                .then(({ data }) => {
                    formData.value = { ...data };
                })
                .catch(({ response }) => {
                    console.log('error', response);
                })
                .finally(() => {
                    loading.value = false;
                    
                    //Get subscriptions when done with loading
                    getSubscriptions();

                    //Get TVOD
                    getVideoContentById();
                });


        }

        const getSubscriptions = () => {
            loading.value = true;
            if (formData.value.pricePlanId && formData.value.pricePlanId !== '') {
                ApiService.get(`PricePlan/` + formData.value.pricePlanId)
                    .then(({ data }) => {
                        //Push as correct obj to array
                        subscriptionObj.value = {
                            orderId: null,
                            pricePlanId: data.pricePlanId,
                            status: "succeeded",
                            adyenSubscription: false,
                            validTill: "2024-09-30T00:00:00",
                            colorValidTill: true,
                            pricePlanName: data.title,
                            pricePlanType: data.planType,
                            price: data.price
                        };
                    })
                    .catch(({ response }) => {
                        console.log('error', response);
                    })
                    .finally(() => {
                        loading.value = false;
                    });
            }
        };

        const deleteSubscription = (subscriptionItemId) => {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: 'Yes, unsubscription it!',
                customClass: {
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                }
            }).then(result => {
                if (result.isConfirmed) {
                    subscriptionObj.value = undefined;

                    formData.value.pricePlanId = '';
                }
            });
        };

        const getVideoContentById = () => {
            console.log("1")
            ApiService.get(`VideoContent/${formData.value.itemId}`)
                .then(({ data }) => {

                    if (data.mediaId) {
                        getVideoAssetById(data.mediaId, 'mediaId');
                    }

                })
                .catch(({ response }) => {
                    console.log('error', response);
                });
        };

        const setSelectedVideoContent = row => {
            console.log(row);
            formData.value.itemId = row.id;
            if (row.mediaId) 
            {
                getVideoAssetById(row.mediaId, 'mediaId');
            }
        };

        const getVideoAssetById = (mId, videoKey) => {
            ApiService.get(`VideoAsset/${mId}`)
                .then(({ data }) => {

                    //console.log(data);
                    videoAssetObj.value = {
                        ...videoAssetObj.value,
                        [videoKey]: data
                    };

                })
                .catch(({ response }) => {
                    console.log('error', response);
                });
        };

        const setVideoKey = key => {
            videoKey.value = key;
        };

        const resetVideoObject = key => {
            videoAssetObj.value = undefined;

            formData.value = {
                ...formData.value,
                ['itemId']: ''
            };
        };

        const setSelectedVideoAsset = row => {
            defaultFilterType.value = '';

            // videoAssetObj.value = {
            //     ...videoAssetObj.value,
            //     [videoKey.value]: row
            // };

            getVideoAssetById(row.mediaId, 'mediaId');

        };

        return {
            role,
            rules,
            isEdit,
            submit,
            userId,
            formRef,
            loading,
            formData,
            subscriptionObj,
            deleteSubscription,
            setSelectedPricePlan,
            defaultFilterType,
            videoAssetObj,
            getVideoContentById,
            getVideoAssetById,
            setVideoKey,
            resetVideoObject,
            setSelectedVideoContent,
            setSelectedVideoAsset,
        };
    }
});
