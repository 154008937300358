
import { defineComponent, ref, watch, onMounted } from 'vue';
import { hideModal } from '@/core/helpers/dom';
import { VMedia } from '@/core/data/videoAsset';
import ApiService from '@/core/services/ApiService';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import moment from 'moment';
import { reinitializeComponents } from '@/core/plugins/keenthemes';

export default defineComponent({
    name: 'add-customer-modal',
    props: {
        selectedCustomer: String,
        defaultFilterType: String
    },
    components: {
        Datatable
    },
    setup(props, { emit }) {
        const loading = ref<boolean>(true);
        const paginationHeader = ref<any>(null);
        const pagingFilteration = ref<any>({
            paging: {
                pageNumber: 1,
                pageSize: 50
            },
            filter: {
                searchTerm: '',
                type: props.defaultFilterType || ''
            },
            orderBy: {
                orderByField: '',
                orderDesc: false
            }
        });
        const addVideoAssetModalRef = ref<null | HTMLElement>(null);

        const tableHeader = ref([
            {
                name: 'Title',
                key: 'title',
                sortable: true
            },
            {
                key: 'checkbox',
                sortable: true
            },
            {
                name: 'Id',
                key: 'mediaId',
                sortable: true
            },
            {
                name: 'Duration',
                key: 'durationFormatted',
                sortable: true
            },
            {
                name: 'Category',
                key: 'categories',
                sortable: true
            },
            {
                name: 'updated At',
                key: 'updatedAt',
                sortable: true
            },
            {
                name: 'created At',
                key: 'createdAt',
                sortable: true
            }
        ]);

        watch(
            () => pagingFilteration.value.paging.pageSize,
            (newValue, oldValue) => {
                console.log('newValue', newValue);
                console.log('oldValue', oldValue);
                getMediaList();
            },
            { deep: true }
        );

        watch(
            () => pagingFilteration.value.paging.pageNumber,
            (newValue, oldValue) => {
                console.log('newValue', newValue);
                console.log('oldValue', oldValue);
                getMediaList();
            },
            { deep: true }
        );

        watch(
            () => props.defaultFilterType,
            (newValue, oldValue) => {
                console.log('newValue', newValue);
                console.log('oldValue', oldValue);
                pagingFilteration.value.filter.type = newValue;
                getMediaList();
            },
            { deep: true }
        );

        const tableData = ref<Array<VMedia>>([]);
        const initMedia = ref<Array<VMedia>>([]);

        const getMediaList = () => {
            loading.value = true;
            ApiService.post(`VideoAsset`, pagingFilteration.value)
                .then(({ data, headers }) => {
                    paginationHeader.value = JSON.parse(headers['x-pagination']);
                    // console.log('paginationHeader.value: ', paginationHeader.value);
                    tableData.value.splice(0, tableData.value.length, ...data);
                    initMedia.value.splice(0, tableData.value.length, ...data);
                    loading.value = false;
                    reinitializeComponents();
                })
                .catch(({ response }) => {
                    loading.value = false;
                    console.log('getMediaList error response:', response);
                });
        };

        const onItemsPerPageChange = itemsPerPage => {
            // console.log('itemsPerPage: ', itemsPerPage);
            pagingFilteration.value.paging.pageSize = itemsPerPage;
        };

        const onCurrentChange = currentPage => {
            // console.log('current page: ', currentPage);
            pagingFilteration.value.paging.pageNumber = currentPage;
        };

        const setSelectedVideoAsset = row => {
            emit('setSelectedVideoAsset', row);
            hideModal(addVideoAssetModalRef.value);
        };

        onMounted(() => {
            getMediaList();
        });

        const search = ref<string>('');

        const searchItems = () => {
            getMediaList();
        };

        const filterType = () => {
            getMediaList();
        };

        return {
            moment,
            search,
            loading,
            tableData,
            tableHeader,
            paginationHeader,
            pagingFilteration,
            addVideoAssetModalRef,
            filterType,
            searchItems,
            onCurrentChange,
            onItemsPerPageChange,
            setSelectedVideoAsset
        };
    }
});
